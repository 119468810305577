/*!*!/ Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming*!
@import '~@angular/material/theming';
!*!/ Plus imports for other components in your app.*!

!*!/ Include the common styles for Angular Material. We include this here so that you only*!
!*!/ have to load a single css file for Angular Material in your app.*!
!*!/ Be sure that you only ever include this mixin once!*!
@include mat-core();
!*
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color!*!
$chbpfrontend-primary: mat-palette($mat-indigo);
$chbpfrontend-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$chbpfrontend-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$chbpfrontend-theme: mat-light-theme($chbpfrontend-primary, $chbpfrontend-accent, $chbpfrontend-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($chbpfrontend-theme);

@import './orange.scss';
@import '~boosted/scss/boosted';*/

body {
  height: 100%;
  margin: 0;
  background-color: #f7f3ec !important;
}

html {
  height: 100%;
  font-family: "Helvetica Neue", sans-serif;
  overflow-x: hidden;
}

app-modal {
  display: none;
}

app-modal > .custom-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
}

app-modal > .custom-modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.58;
  z-index: 900;
}

.custom-modal > .custom-modal-body {
  background: #fff;
}

body.custom-modal-open {
  overflow: hidden;
}

.hand {
  cursor: pointer;
}

.mainContainer {
  /*padding: 5px 10px;*/
  margin-bottom: auto;
  /*background: #efefef !important;*/
}

.ftsize08 {
  font-size: 0.8rem !important;
}

.head .btn {
  margin-left: 1em;
}

html, body {
  height: 100%;
  /*background: #efefef !important;*/
  background: light !important;
}

@font-face {
  font-family: lato;
  src: url('DavidLibre-Regular.ttf') format("opentype");
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.focusOnBoard {
@include mat-elevation-transition;
@include mat-elevation(8);
  z-index: 10001 !important;
  position: relative;
}

.blur {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.cdk-overlay-container {
  z-index: 10000 !important;
}

.globalWidgetHeader {
  min-height: 35px;
  background-color: #eeeeee !important;
  min-width: 100%;
  color: #7f7f7f;
  text-align: center;
  font-size: 1rem;
  padding: 8px;
  margin-bottom: 10px;
}

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
